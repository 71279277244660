<template lang="pug">
    v-card
      v-card-title.d-flex.justify-center {{$t('ADD_AINOTEBOOK.TITLE')}}{{$t('ADD_AINOTEBOOK.COLUMN')}}
      v-card-text
        v-form(v-model="valid", ref="form")
          v-text-field.mb-4(
            v-model='addData.name',
            :label=`$t('CUSTOMFIELDS.NAME')` name='name',
            :rules='formRule.nameRules', 
            required='required', 
            large='large'
          )
          v-flex
            v-select(
              v-model='addData.type',
              :label=`$t('CUSTOMFIELDS.TYPE')` name='name',
              required='required', 
              :items='recordType' 
              item-value='key' 
              item-text='name'
              :rules='formRule.reqRules'
            )
          v-textarea.no-border.textarea-style(
            v-model='addData.description',
            prepend-icon="mdi-text",
            :label=`$t('ADD_KNOWLEDGE.DESCRIPTION')`,
            :hint='$t("ADD_AINOTEBOOK.LIMIT_HINT")',
            :rules='formRule.descRules', 
            persistent-hint
            no-resize
          )
          div(v-if="addData.type === 'opt' || addData.type === 'mlt'")
              .text-h6.mb-4 
              v-row(no-gutters)
                v-col.d-flex.align-center(cols="12")
                  h3.my-0 {{$t('CUSTOMFIELDS.OPTION')}}

              v-text-field#options.v-text-field__slot(
                type="text",
                :label=`$t('CUSTOMFIELDS.ERROR_6')`,
                v-model="tempOptions",
                @keyup.enter="addOptions"
              )
              v-card(v-show='Object.keys(newOption).length>0' max-height="330" class='scroll' outlined elevation='0')
                v-card-text(height='100' max-height='260' class='custom-filter-style pa-1 ')
                  v-list(max-height='260' class='pa-0')
                    v-text-field.no-border.v-text-field__slot.pt-0(
                      type="text",
                      :rules='formRule.reqRules'
                      prepend-inner-icon="mdi-format-list-bulleted"
                      v-model="newOption[index]",
                      v-for="(item,index) in newOption", :key="index" :append-outer-icon="'icon-cancel'" @click:append-outer="deleteOtptions(index,'new')"
                    )
      v-card-actions.px-2.py-4
        v-row
          v-col.pr-2(cols="6")
            v-btn(
              :ripple="false",
              @click="onCancel",
              width="100%",
              height="40",
              color="cancel",
              depressed,
              dark
            ) {{$t('GENERAL.CANCEL')}}
          v-col.pl-2(cols="6")
            v-btn.btn_green(
              :ripple="false",
              @click="onConfirm",
              width="100%",
              height="40",
              color="#2ace87",
              depressed,
              dark,
            ) 
              v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
              .t-white(v-else) {{$t('GENERAL.ADD')}}
      v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
          message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
      v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
          error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    
    </template>
    
    <script>
    import Vue from "vue";
    import messageDialog from '@/components/Dialog/redirectDialog';
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    
    
    export default Vue.extend({
      props: {
        showList:{
          type:Array,
          require:true
        },
        maxKey:{
          type:Number,
          require:true
        }
      },
      components: {            
                messageDialog,
                errorDialog
            },
      data() {
        return {
          addBtn_loading:false,
          addData : {},
          messageDialog:false,
          message:"",
          errorDialog: false,
          errorMessage: "",
          valid: true,
          formRule: {
            reqRules: [
                (v) => !!v.trim() || i18n.t('RULE.RULE_R'),
            ],
            nameRules: [
                (v) => !!v.trim() || i18n.t('RULE.RULE_R'),
                (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20'),
            ],
            descRules: [
                (v) => !!v || i18n.t('RULE.RULE_R'),
                (v) => /^.{10,50}$/.test(v) || i18n.t('RULE.RULE_10_50'),
            ],
          },
          recordType: [
              {key: 'txt', name: 'Text'},
              {key: 'str', name: 'String' },
              {key: 'num', name: 'Number' },
              {key: 'dat', name: 'Date' },
              {key: 'dtt', name: 'Date Time' },
              {key: 'bol', name: 'Boolean' },
              {key: 'opt', name: 'Select' },
          ],
          tempOptions: "",
          options: [],
          newOption:{},
          processOptData:{},
          newOptList:{},
        };
      },
      created(){
        this.addData.name = '';
        this.addData.description = '';
        this.addData.record_type = '';
      },
      methods: {
        validateForm() {
          if (this.$refs.form.validate()) 
            return true;
          else 
            return false;
        },
        async onConfirm() {
          if(this.addData.type=="opt" || this.addData.type=="mlt"){
            if(Object.entries(this.newOption).length < 2)
              {
                this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR'));
                return
              }
            for(let i in this.newOption){
              if(this.checkExist(this.newOption[i])[0] != true){
                this.showErrorDialog(this.checkExist(this.newOption[i])[0]);
                return;
              }
            }
          }
          if (this.validateForm()){
            let showList = JSON.parse(JSON.stringify(this.showList))
            if(this.showList.length >= 10){
              this.showErrorDialog(i18n.t("ADD_AINOTEBOOK.LIMIT_COLUMNS"))
              return;
            }
            let newKey = this.maxKey+1;
            let data = {
              "column_name":this.addData.name.trim(),
              "column_id":this.addData.type + ((newKey).toString()).padStart(3, "0"),
              "description":this.addData.description,
              "source_type": "ai_notes",
              "record_type": "ai_notes"
            }
            if(data.description.length > 50){
              this.showErrorDialog(i18n.t("ADD_AINOTEBOOK.LIMIT_HINT"))
              return;
            }
            if(this.addData.type=="opt" || this.addData.type=="mlt"){
              data.option_set = this.newOption;
              data.max_key = Object.entries(this.newOption).length;
            }
            showList.push(data)
            let edit_data = {
              "ai_notes" : showList,
              "ai_notes_max_key" : newKey
            }
            this.$emit("emitAddDialog", true ,edit_data);
          }
        },
        onCancel() {     
          this.$emit("emitAddDialog", false);
        },
        checkExist(item){
          let optCheck = null;
          if(this.addData.type =="mlt" || this.addData.type =="opt"){
            optCheck = Object.values(this.newOption).filter(el => el ==item);
          }else{
            optCheck = Object.values(this.editOptItems.option_set).filter(el => el ==item);
          }
          if((this.optType =="mlt" || this.addData.type =="mlt") && item.includes(",")){
            return [false || i18n.t("RULE.RULE_CF_2")];
          }
          if (optCheck.length >1){
            return [false || i18n.t("RULE.RULE_CF_1")];
          }else{
            return [true];
          }
        },
        addOptions(e) {
          if (e.key === "Enter" && this.tempOptions) {
            const optCheck = Object.values(this.newOption).includes(this.tempOptions) 
            if(this.addData.type =="mlt" && this.tempOptions.includes(",")){
              return this.tempOptions ="";
            }
            if (!optCheck) {
              this.newOption[Object.entries(this.newOption).length] = this.tempOptions;
            }
            this.tempOptions = "";
          }else{        
            this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_2'));
          }
        },
        deleteOtptions(index,mode) {
          this.processOptData = this.newOption;
          const lastIndex = Object.entries(this.processOptData).length;
          if(mode == "new"){
              for(let i in this.processOptData){
                if(i>index){
                  this.processOptData[index++] = this.processOptData[i++];
                }
            }
            Vue.delete(this.processOptData,lastIndex-1);
          }
        },
        // Dialog
        showMessageDialog(msg) {                
            this.messageDialog = true;
            this.message = msg;
        },
        async onEmitMessageDialog(val) {
          if(val){
            // 
          }
        },
        showErrorDialog(message) {
          this.errorDialog = true;
          this.errorMessage = message;
        },
        onEmitErrorDialog() {
          this.errorDialog = false;
        },
      },
    });
    </script>