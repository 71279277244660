<template lang="pug">
v-card
  v-card-title.d-flex.justify-center {{$t('GENERAL.EDIT')}}{{$t('GPT_MEETINGRECAP.TITLE')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-text-field.mb-4(
        v-model='editData.name',
        :label=`$t('GPT_MEETINGRECAP.NAME')` name='name',
        :rules='formRule.nameRules', 
        required='required', 
        large='large'
      )
  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn.btn_green(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="#2ace87",
          depressed,
          dark,
        ) 
          v-progress-circular(v-if='editBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.SAVE')}}
  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
</template>

<script>
import Vue from "vue";
import messageDialog from '@/components/Dialog/redirectDialog';
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import UpMeetingService from "@/services/UpMeetingService";

export default Vue.extend({
  props: {
    edit_data: {
        type: Object,
        required: true,
    },
  },
  components: {            
            messageDialog,
            errorDialog,
        },
  data() {
    return {
      editBtn_loading:false,
      editData : {},
      messageDialog:false,
      message:"",
      errorDialog: false,
      errorMessage: "",
      valid: true,
      formRule: {
        nameRules: [
            (v) => !!v || i18n.t('RULE.RULE_R'),
            (v) => /^.{0,300}$/.test(v) || i18n.t('RULE.RULE_300'),
        ],
      },
    };
  },
  created(){
    this.editData.id = this.edit_data.id;
    this.editData.name = this.edit_data.name;
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) 
        return true;
      else 
        return false;
    },
    async onConfirm() {
      if (this.validateForm() && !this.editBtn_loading) {
        this.editBtn_loading = true;
        UpMeetingService.editMeetingRecap(this.editData).then((response)=>{
          this.$emit("emitEditDialog", true ,response.data);
        })
      }
    },
    onCancel() {     
      this.$emit("emitEditDialog", false);
    },
    showMessageDialog(msg) {                
        this.messageDialog = true;
        this.message = msg;
    },
    async onEmitMessageDialog(val) {
      if(val){
        // 
      }
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
  },
});
</script>