<template lang="pug">
v-card
  v-card-title.d-flex.justify-center {{$t('ADD_AIBOTS.TITLE')}}
  v-card-text
    v-form(v-model="valid", ref="form")
      v-text-field(
        v-model='addData.bot_name',
        :label=`$t('ADD_AIBOTS.NAME')`
        :rules='formRule.rules100', 
        required='required', 
        large='large'
        persistent-hint
      )
      v-select(
        :items='assistant_language_list'
        item-text='name' 
        item-value='key'
        v-model='addData.answer_language',
        :label=`$t('GPT_AIBOTS.REPLY_LANGUAGE')`
        :rules='formRule.rules100', 
        required='required', 
        large='large'
        persistent-hint
      )
      v-text-field(
        v-model='addData.assistant_name',
        :label=`$t('GPT_AIBOTS.AIASSISTANTNAME')`
        :rules='formRule.rules100', 
        required='required', 
        large='large'
        persistent-hint
      )
      v-text-field(
        v-model='addData.assistant_role',
        :label=`$t('GPT_AIBOTS.AIASSISTANTROLE')`
        :rules='formRule.rules500', 
        required='required', 
        large='large'
        persistent-hint
      )
      v-text-field(
        v-model='addData.conversation_topic',
        :label=`$t('GPT_AIBOTS.TOPIC')`
        :rules='formRule.rules500', 
        required='required', 
        large='large'
        persistent-hint
      )
      v-text-field(
        v-model='addData.conversation_topic_limit',
        :label=`$t('GPT_AIBOTS.TOPICLIMIT')`
        :rules='formRule.rules500', 
        required='required', 
        large='large'
        persistent-hint
      )
      //- GPT3.5 4.0
      v-select(
        :items='model_list'
        item-text='name' 
        item-value='id'
        v-model='addData.deployment_name',
        :label=`$t('GPT_AIBOTS.MODEL')`
        :rules='formRule.nameRules', 
        required='required', 
        large='large'
        persistent-hint
      )
      //- 1-3
      v-select(
        :items='memory_length_list' 
        v-model='addData.memory_length',
        :label=`$t('GPT_AIBOTS.MEMORY_LENGTH')`
        :rules='formRule.nameRules', 
        required='required', 
        large='large'
        persistent-hint
      )
      //- 3-20
      v-select(
        :items='return_knowledge_list' 
        v-model='addData.return_knowledge',
        :label=`$t('GPT_AIBOTS.RETURN_KNOWLEDGE')`
        :rules='formRule.nameRules', 
        required='required', 
        large='large'
        persistent-hint
      )
      v-autocomplete(
        v-model='select_inbox' 
        :label=`$t('GPT_AIBOTS.INBOX')`
        :rules='formRule.nameRules', 
        :items='inbox_list' 
        item-text='name' 
        item-value='id'  
        return-object
        :menu-props='{closeOnClick:true}'
      )
        template(v-slot:selection="data")
            .d-flex.justify-space-between
              v-img(v-if='data.item.channel == "teams"' src='@/assets/images/teams_logo.png' width='25px')
              v-icon(v-else :color='get_upchat_channel_icon(data.item.channel).color') {{get_upchat_channel_icon(data.item.channel).icon}}
              v-list-item-title.pl-1.pt-1(v-html="data.item.name")
        template(v-slot:item="data")
          v-img(v-if='data.item.channel == "teams"' src='@/assets/images/teams_logo.png' width='25px')
          v-icon(v-else :color='get_upchat_channel_icon(data.item.channel).color') {{get_upchat_channel_icon(data.item.channel).icon}}
          v-list-item-title.pl-4(v-html="data.item.name")
      v-textarea.no-border.textarea-style(
        v-model="addData.description",
        prepend-icon="mdi-text",
        :label=`$t('ADD_AIBOTS.DESCRIPTION')`,no-resize
      ) 
  v-card-actions.px-2.py-4
    v-row
      v-col.pr-2(cols="6")
        v-btn(
          :ripple="false",
          @click="onCancel",
          width="100%",
          height="40",
          color="cancel",
          depressed,
          dark
        ) {{$t('GENERAL.CANCEL')}}
      v-col.pl-2(cols="6")
        v-btn.btn_green(
          :ripple="false",
          @click="onConfirm",
          width="100%",
          height="40",
          color="#2ace87",
          depressed,
          dark,
        ) 
          v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
          .t-white(v-else) {{$t('GENERAL.ADD')}}
  v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
      message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
  v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
      error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')

</template>

<script>
import Vue from "vue";
import messageDialog from '@/components/Dialog/redirectDialog';
import errorDialog from "@/components/Dialog/errorDialog";
import i18n from '/common/plugins/vue-i18n.js';
import AiBotsService from "@/services/AiBotsService";
import AssistantLanguageList from "@/array/the_assistant_languageList";
import GPTModelList from "@/array/the_gpt_modelList";

export default Vue.extend({
  props: {
    inbox_list: {
        type: Array,
        required: true,
    },
  },
  components: {            
            messageDialog,
            errorDialog
        },
  data() {
    return {
      select_inbox:null,
      addBtn_loading:false,
      addData : {},
      return_knowledge_list:[],
      memory_length_list:[],
      model_list : GPTModelList,
      assistant_language_list: AssistantLanguageList,
      messageDialog:false,
      message:"",
      errorDialog: false,
      errorMessage: "",
      valid: true,
      formRule: {
        nameRules: [
            (v) => !!v || i18n.t('RULE.RULE_R'),
            (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
        ],
        rules100: [
            (v) => !!v || i18n.t('RULE.RULE_R'),
            (v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100'),
        ],
        rules500: [
            (v) => !!v || i18n.t('RULE.RULE_R'),
            (v) => /^.{0,500}$/.test(v) || i18n.t('RULE.RULE_500'),
        ],
      },
    };
  },
  async created(){
    for(let i = 1; i<11;i++){
      if(i<=3){
        this.memory_length_list.push(i);
      }
      if(i>=3){
        this.return_knowledge_list.push(i);
      }
    }
  },
  methods: {
    get_upchat_channel(data){
        let channel_str = data.toLowerCase()
        let channel_name = ''
        switch(true){
            case channel_str.includes('facebook'):
                return "Facebook"
            case channel_str.includes('line'):
                return "Line"
            case channel_str.includes('webchat'):
                return "Webchat"
            case channel_str.includes('whatsapp'):
                return "WhatsApp"
            case channel_str.includes('sms'):
                return "SMS"
            case channel_str.includes('mail'):
                return "Mail"
            default:
                if(data.split('::').length>1){
                  channel_name = data.split('::')[1];
                }else{
                  channel_name = 'Web';
                }
                return channel_name
        }
    },
    get_upchat_channel_icon(data){
        let channel_str = data.toLowerCase()
        switch(true){
            case channel_str.includes('facebook'):
                return {icon:'mdi-facebook',color:'blue'}
            case channel_str.includes('line'):
                return {icon:'fab fa-line',color:'green'}
            case channel_str.includes('webchat'):
                return {icon:'mdi-earth',color:'blue'}
            case channel_str.includes('whatsapp'):
                return {icon:'mdi-whatsapp',color:'green'}
            case channel_str.includes('sms'):
                return {icon:'mdi-message-text-outline',color:'blue'}
            case channel_str.includes('mail'):
                return {icon:'mdi-email',color:'blue'}
            default:
                return {icon:'mdi-earth',color:'blue'}
        }
    },
    validateForm() {
      if (this.$refs.form.validate()) 
        return true;
      else 
        return false;
    },
    async onConfirm() {
      if (this.validateForm() && !this.addBtn_loading) {
        this.addBtn_loading = true;
        this.addData.channel = this.select_inbox.channel;
        this.addData.inbox_id = this.select_inbox.id;
        this.addData.transfer_human_reply = "transfer_human_reply";
        AiBotsService.create(this.addData).then((response)=>{
          if(response.data == "Has agent bot"){
            this.showErrorDialog(i18n.t('GPT_AIBOTS.HAS_AGENT_BOT'));
            this.addBtn_loading = false;
          }else if(response.data == "No agent bot"){
            this.showErrorDialog(i18n.t('GPT_AIBOTS.NO_AGENT_BOT'));
            this.addBtn_loading = false;
          }
          else{
            this.$emit("emitAddDialog", true ,response.data);
          }
        })
      }
    },
    onCancel() {     
      this.$emit("emitAddDialog", false);
    },
    showMessageDialog(msg) {                
        this.messageDialog = true;
        this.message = msg;
    },
    async onEmitMessageDialog(val) {
      if(val){
        // 
      }
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
  },
});
</script>