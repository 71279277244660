<template lang="pug">
    v-card
        v-card-title.d-flex.align-center.flex-column.justify-center.py-8.pb-2.px-3
            h5(style='font-weight:600;word-break:keep-all;text-align: center;') {{$t('UPMEETING.L_D_TITLE')}}
            v-icon.pt-2(size='70' color='#59abb6') fa-regular fa-comment-dots
        v-card-actions.px-2.pb-6
            v-row.d-flex.justify-center
                v-col(cols='10')
                    v-btn(:ripple='false' @click='onLast' min-width='100px' width='100%' height='40' color='#59abb6' depressed='depressed' dark='dark')
                        span.ellipsis {{$t('UPMEETING.L_D_LAST')}}
                v-col(v-if='!isGoogle' cols='10')
                    v-btn(:ripple='false' @click='onNew' min-width='100px' width='100%' height='40' color='#59abb6' depressed='depressed' dark='dark') {{$t('UPMEETING.L_D_NEW')}}
                v-col(cols='10')
                    v-btn(:ripple='false' @click='onFinish' min-width='100px' width='100%' height='40' color='#ff6363' depressed='depressed' dark='dark') {{$t('UPMEETING.FINISH')}}
</template>

<script>
	import Vue from 'vue';

	export default Vue.extend({
        props: {
            isGoogle: {
                type: Boolean,
                required: true,
            },
        },
        methods: {
			onLast() {
				this.$emit('emitLastmeetDialog',"Last");
			},
			onNew() {
				this.$emit('emitLastmeetDialog',"New");
			},
            onFinish() {
				this.$emit('emitLastmeetDialog',false);
			},
		}
	});
</script>