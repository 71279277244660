<template lang="pug">
    v-card
      //- v-card-title.d-flex.justify-center 
      .d-flex.align-center.justify-start.mx-4.mt-8
        div.mr-3
          v-img.mb-4(src="@/assets/images/add_column_icon.png")
        .d-flex.flex-column
          h5 {{$t("CUSTOMFIELDS.ADD_FIELD")}}
          p {{$t("CUSTOMFIELDS.SELECT_MESSAGE")}}

      v-card-text
        v-form(v-model="valid", ref="form")
          v-data-table.dashboard-table.ellipsis(
            v-model='selectedRows'
            :headers='FieldHeaders'
            :items='fieldList' 
            :items-per-page="itemPrePage" 
            hide-default-footer
            :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
            :page.sync='page'
            show-select
            @page-count='pageCount=$event' 
            height='100%'
            item-key='id'
            :loading='isLoading' 
            loading-text="Loading..."
            )
            template( v-slot:item.type="{ item }" )
                .ellipsis
                    span {{getTypeStr(item.type)}}
            //- template(v-slot:item.action='{ item }')
            //-   .ellipsis
            //-     v-checkbox(label="Checkbox")
        //-   v-text-field.mb-4(
        //-     v-model='addData.name',
        //-     :label=`$t('ADD_KNOWLEDGE.NAME')` name='name',
        //-     :rules='formRule.nameRules', 
        //-     required='required', 
        //-     large='large'
        //-     :hint='$t("ADD_AINOTEBOOK.NAME_HINT")'
        //-     persistent-hint
        //-   )
        //-   v-flex
        //-     v-select(
        //-       v-model='addData.record_type',
        //-       :items='businessMode' 
        //-       item-value='key' 
        //-       item-text='name'
        //-     )
        //-   v-textarea.no-border.textarea-style(
        //-     v-model='addData.description',
        //-     prepend-icon="mdi-text",
        //-     :label=`$t('ADD_KNOWLEDGE.DESCRIPTION')`,
        //- no-resize
        //-   ) 
        v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10")
      v-card-actions.px-2.py-4
        v-row
          v-col.pr-2(cols="6")
            v-btn(
              :ripple="false",
              @click="onCancel",
              width="100%",
              height="40",
              color="cancel",
              depressed,
              dark
            ) {{$t('GENERAL.CANCEL')}}
          v-col.pl-2(cols="6")
            v-btn.btn_green(
              :ripple="false",
              @click="onConfirm",
              width="100%",
              height="40",
              color="#2ace87",
              depressed,
              dark,
            ) 
              v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
              .t-white(v-else) {{$t('GENERAL.ADD')}}
      v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
          message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
      v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
          error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    
    </template>
    
    <script>
    import Vue from "vue";
    import messageDialog from '@/components/Dialog/redirectDialog';
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    // import UpMeetingService from "@/services/UpMeetingService";
    
    
    export default Vue.extend({
      props: {
        fieldList:{
          type:Array,
          require:true
        },
        showList:{
          type:Array,
          require:true
        }
      },
      components: {            
                messageDialog,
                errorDialog,
            },
      data() {
        return {
          selectedRows:[],
          page:1,
          pageCount:0,
          itemPrePage:6,
          isLoading:false,
          addBtn_loading:false,
          addData : {},
          messageDialog:false,
          message:"",
          errorDialog: false,
          errorMessage: "",
          valid: true,
          formRule: {
            nameRules: [
                (v) => !!v || i18n.t('RULE.RULE_R'),
                (v) => /^.{0,100}$/.test(v) || i18n.t('RULE.RULE_100'),
            ],
          },
          FieldHeaders: [
          {
                text: '選擇',
                sortable: false,
                value: "data-table-select",
                align: "left",
                width: '25%',
          },
          {
                text: i18n.t('CUSTOMFIELDS.NAME'),
                align: 'left',
                sortable: false,
                value: 'name',
                width: '25%',
            },
            {
                text: i18n.t('CUSTOMFIELDS.TYPE'),
                align: 'left',
                value: 'type',
                sortable: false,
                width: '25%',
            },
      ],
        };
      },
      created(){
        this.isLoading = true;
        this.selectedRows = this.showList;
        this.isLoading = false;
      },
      methods: {
        getTypeStr(val){
            switch(val){
                case "txt":
                    return "Text";
                case "str":
                    return "String";
                case "num":
                    return "Number";
                case "dat":
                    return "Date";
                case "dtt":
                    return "Date Time";
                case "bol":
                    return "Boolean";
                case "opt":
                    return "Select";
                default:
                    break;
            }
        },
        validateForm() {
          if (this.$refs.form.validate()) 
            return true;
          else 
            return false;
        },
        async onConfirm() {
          let data = JSON.parse(JSON.stringify(this.selectedRows)).map((el=>{
            delete el.id;
            delete el.name;
            delete el.type;
            return el;
          }))
          this.$emit("emitAddFieldDialog", true,data);
        },
        onCancel() {     
          this.$emit("emitAddFieldDialog", false);
        },
        showMessageDialog(msg) {                
            this.messageDialog = true;
            this.message = msg;
        },
        async onEmitMessageDialog(val) {
          if(val){
            // 
          }
        },
        showErrorDialog(message) {
          this.errorDialog = true;
          this.errorMessage = message;
        },
        onEmitErrorDialog() {
          this.errorDialog = false;
        },
      },
    });
    </script>