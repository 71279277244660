<template lang="pug">
    v-card.px-2.py-4
      v-overlay(v-show='loading || ai_loading' opacity='0' z-index='999')
      v-card-text(v-if='loading').d-flex.justify-center.align-center
          h4.pt-5 {{$t('GPT_MEETINGRECAP.MAIL_SETTING')}}
          v-progress-circular.mt-2.ml-3(v-if='loading' indeterminate size='20')
      v-card-text(v-else-if='ai_loading && type!="customType"').d-flex.justify-center.align-center
          h4.pt-5 {{$t('GPT_MEETINGRECAP.MAIL_GENERATE')}}
          v-progress-circular.mt-2.ml-3(v-if='ai_loading' indeterminate size='20')
      v-card-text.pb-0(v-if='!loading && !ai_loading || (ai_loading && type=="customType") ')
          v-row.py-5(v-if='!hasO365 || invalidO365Token' no-gutters).d-flex.justify-center.align-center
            h6(v-if='!hasO365') {{$t('EMAILEDITOR.NO_O365')}}
            h6(v-if='hasO365 && invalidO365Token') {{$t('EMAILEDITOR.INVALID_O365_TOKEN')}}
            h6
              a.t-primary.mx-4.ellipsis(:href="'/Setting#EmailSync'" color='black' text) {{$t('EMAILEDITOR.GO_SETTING')}}
          v-row(v-if='!hasO365 || invalidO365Token' no-gutters).d-flex.justify-center.align-center
              v-btn(:ripple="false", @click="onCancel", width="90", color="cancel", depressed, dark ) {{$t('GENERAL.CLOSE')}}
          v-card.elevation-0.pa-0.ma-0.pb-2(v-if='type == "customType" && hasO365 && !invalidO365Token')
            v-form
              v-card-actions(v-if='!hasMailContent').px-0
                  v-row.justify-end.pt-1
                      //- v-btn.elevation-0(width='90' @click='onCancel()') {{$t('GENERAL.CANCEL')}}
                      v-btn(:ripple="false", @click="onCancel", width="110", color="cancel", depressed, dark ) {{$t('GENERAL.CANCEL')}}
              v-card-actions.px-0
                  v-row 
                      v-col.px-0.py-0.mt-6
                          v-textarea(v-model='prompt' :label="'Enter your prompt...'" auto-grow rows="4" :loading='ai_loading' row-height="30" outlined no-resize)
              v-card-actions.px-0
                  v-row.justify-end
                      v-btn.btn_green.elevation-0(width='110' color="#2ace87", depressed dark  @click='getMailContent()') 
                          v-icon(v-if='!ai_loading' size='15' color="#fff").mr-2 fa fa-magic 
                          .t-white(v-if='!ai_loading') {{$t('GPT_MEETINGRECAP.ANALYZE')}}
                          v-progress-circular(v-if='ai_loading' indeterminate size='20')
          v-divider.mt-1(v-if='type=="customType" && hasMailContent')
          emailEditor(v-if='hasO365 && !invalidO365Token && hasMailContent' :mailContent='mailContent' :objectDetail='opptyData' :contactsDetail='{}' objectType='Opportunity' :contactEmail='""' :userEmail='userEmail' @emitSendEmailDialog='onEmitSendEmailDialog')
 
      v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
          message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
      v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
          error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')  
  </template>
      
      <script>
      import Vue from "vue";
      import { VueEditor } from "vue2-editor";
      import messageDialog from '@/components/Dialog/redirectDialog';
      import errorDialog from "@/components/Dialog/errorDialog";
      import i18n from '/common/plugins/vue-i18n.js';

      import UpMeetingService from "@/services/UpMeetingService";
      import PreferenceService from "@/services/PreferenceService";
      import O365Service from "@/services/O365Service";
      import emailEditor from '@/components/GPT_Dialog/MeetingRecap/AiEmail/aiEmailEditor';
      
      export default Vue.extend({
        props: {
          meetingData:{
            type:Object,
            require:true
          },
          type:{
            type:String,
            require:true
          }
        },
        components: {            
                  messageDialog,
                  errorDialog,
                  VueEditor,
                  emailEditor
              },
        data() {
          return {
            userEmail:'',
            opptyData:{},
            hasO365:false,
            invalidO365Token: false,
            loading:false,
            ai_loading:false,
            hasMailContent:false,
            mailContent:{
              "subject":"",
              "content":""
            },
            prompt:"",
            messageDialog:false,
            message:"",
            errorDialog: false,
            errorMessage: "",
            valid: true,
          };
        },
        async created(){
          this.loading = true;
          this.opptyData = this.meetingData.opportunity_id ? this.meetingData.opportunity_id:{};
          await this.getLoginUserEmail();
          if(this.type != "customType"){
            await this.getMailContent();
          }
        },
        methods: {
          async getLoginUserEmail() {
                await O365Service.getO365Accounts()
                .then(response => {
                    this.userEmail = response.data[0]? response.data[0].o365_user_principal_name:null;
                    this.invalidO365Token = (response.data[0].refresh_token_message != '') ? true : false;
                    this.hasO365 =  response.data.length >0 ? true:false;
                }).finally(()=>{
                  this.loading = false;
                });
          },
          async getMailContent(){
                // // 測試用
                // this.ai_loading = true;
                // setTimeout(()=>{
                //   this.ai_loading = false;
                //   this.mailContent.subject = "123";
                //   this.mailContent.content = "123";
                //   this.hasMailContent = true;
                // },2000)
                this.ai_loading = true;
                let senddata = {};
                if (this.type == 'customType')
                  senddata = {
                    "meeting_recap_id":this.meetingData.id,
                    "mail_type":this.type,
                    "prompt":this.prompt,
                  };
                else
                  senddata = {
                    "meeting_recap_id":this.meetingData.id,
                    "mail_type":this.type,
                  };
                let key = "";
                await PreferenceService.getApiKey().then((response)=>{
                  if(response.data == ""){
                    return;
                  }
                  key = response.data;
                })
                await UpMeetingService.AiMeetingMailGenerate(senddata,key).then((response)=>{
                  this.hasMailContent = false;
                  if (response.data == 'empty_summary'){
                    this.ai_loading = false;
                    this.showErrorDialog(i18n.t('GPT_MEETINGRECAP.EMPTY_SUMMARY'));
                  }
                  else if (response.data == 'empty_custom_prompt'){
                    this.ai_loading = false;
                    this.showErrorDialog(i18n.t('GPT_MEETINGRECAP.EMPTY_CUSTOM_PROMPT'));
                  }
                  else{
                    this.mailContent.subject = response.data.email_title;
                    this.mailContent.content = response.data.email_contents;
                    this.ai_loading = false;
                  }
                }).finally(()=>{
                  if(this.mailContent.subject && this.mailContent.content){
                    this.hasMailContent = true
                  }
                })
          },
          onEmitSendEmailDialog(val){
              this.$emit("emitMeetiEmailDialog",val);
          },
          validateForm() {
            if (this.$refs.form.validate()) 
              return true;
            else 
              return false;
          },
          onCancel(){
            this.$emit("emitMeetiEmailDialog",false);
          },
          showMessageDialog(msg) {                
              this.messageDialog = true;
              this.message = msg;
          },
          async onEmitMessageDialog(val) {
            if(val){
              // 
            }
          },
          showErrorDialog(message) {
            this.errorDialog = true;
            this.errorMessage = message;
          },
          onEmitErrorDialog() {
            this.errorDialog = false;
            this.onCancel();
          },
        },
      });
      </script>