<template lang="pug">
    v-card
      v-card-title.d-flex.justify-center {{$t('ADD_AINOTEBOOK.TITLE')}}
      v-card-text
        v-form(v-model="valid", ref="form")
          v-text-field.mb-4(
            v-model='addData.name',
            :label=`$t('ADD_AINOTEBOOK.NAME')` name='name',
            :rules='formRule.nameRules', 
            required='required', 
            large='large'
          )
          v-flex
            v-select(
              v-model='addData.record_type',
              :label=`$t('GPT_AINOTEBOOK.RECORD_TYPE')` name='name',
              required='required', 
              :items='recordTypeMode' 
              :rules='formRule.nameRules',
              item-value='key' 
              item-text='name'
            )
          v-textarea.no-border.textarea-style(
            v-model='addData.description',
            prepend-icon="mdi-text",
            :label=`$t('ADD_KNOWLEDGE.DESCRIPTION')`,
        no-resize
          ) 
      v-card-actions.px-2.py-4
        v-row
          v-col.pr-2(cols="6")
            v-btn(
              :ripple="false",
              @click="onCancel",
              width="100%",
              height="40",
              color="cancel",
              depressed,
              dark
            ) {{$t('GENERAL.CANCEL')}}
          v-col.pl-2(cols="6")
            v-btn.btn_green(
              :ripple="false",
              @click="onConfirm",
              width="100%",
              height="40",
              color="#2ace87",
              depressed,
              dark,
            ) 
              v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
              .t-white(v-else) {{$t('GENERAL.ADD')}}
      v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
          message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
      v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
          error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    
    </template>
    
    <script>
    import Vue from "vue";
    import messageDialog from '@/components/Dialog/redirectDialog';
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    import UpMeetingService from "@/services/UpMeetingService";
    
    
    export default Vue.extend({
      props: {
      },
      components: {            
                messageDialog,
                errorDialog
            },
      data() {
        return {
          addBtn_loading:false,
          addData : {},
          messageDialog:false,
          message:"",
          errorDialog: false,
          errorMessage: "",
          valid: true,
          formRule: {
            nameRules: [
                (v) => !!v || i18n.t('RULE.RULE_R'),
                (v) => /^.{0,200}$/.test(v) || i18n.t('RULE.RULE_200'),
            ],
          },
          recordTypeMode: [
          {key: 'opportunity', name: i18n.t('BUSINESS_TYPE.OPPTY') },
          // {key: 'case', name: i18n.t('BUSINESS_TYPE.CASE') },
          // {key: 'company', name: i18n.t('BUSINESS_TYPE.COMPANY') },
          // {key: 'contact', name: i18n.t('BUSINESS_TYPE.CONTACT') },
          {key: 'ai_notes', name: i18n.t('BUSINESS_TYPE.AI_NOTEBOOK') },
      ],
        };
      },
      created(){
        this.addData.name = '';
        this.addData.description = '';
        this.addData.field_definition= {"ai_notes": [], "opportunity":[],"ai_notes_max_key":0};
        this.addData.record_type = 'opportunity';
      },
      methods: {
        validateForm() {
          if (this.$refs.form.validate()) 
            return true;
          else 
            return false;
        },
        async onConfirm() {
          if (this.validateForm() && !this.addBtn_loading) {
            this.addBtn_loading = true;
            UpMeetingService.createAiNotebook(this.addData).then((response)=>{
              if(response.data == "same name block"){
                this.showErrorDialog(i18n.t('GPT_KNOWLEDGE.ERROR'));
                this.addBtn_loading = false;
              }else{
                this.$emit("emitAddDialog", true ,response.data);
              }
            })
          }
        },
        onCancel() {     
          this.$emit("emitAddDialog", false);
        },
        showMessageDialog(msg) {                
            this.messageDialog = true;
            this.message = msg;
        },
        async onEmitMessageDialog(val) {
          if(val){
            // 
          }
        },
        showErrorDialog(message) {
          this.errorDialog = true;
          this.errorMessage = message;
        },
        onEmitErrorDialog() {
          this.errorDialog = false;
        },
      },
    });
    </script>