<template lang="pug">
    v-card
      v-card-title.d-flex.justify-center {{$t('ADD_AINOTEBOOK.EDIT')}}{{$t('ADD_AINOTEBOOK.COLUMN')}} 
      v-card-text
        v-form(v-model="valid", ref="form")
          v-text-field.mb-4(
            v-model='editData.column_name',
            :label=`$t('CUSTOMFIELDS.NAME')` name='name',
            :rules='formRule.nameRules', 
            required='required', 
            large='large'
          )
          v-flex
            //- v-select(
            //-   v-model='editData.type',
            //-   :label=`$t('CUSTOMFIELDS.TYPE')` name='name',
            //-   required='required', 
            //-   :items='recordType' 
            //-   item-value='key' 
            //-   item-text='name'
            //- )
          v-textarea.no-border.textarea-style(
            v-model='editData.description',
            prepend-icon="mdi-text",
            :label=`$t('ADD_KNOWLEDGE.DESCRIPTION')`,
            :hint='$t("ADD_AINOTEBOOK.LIMIT_HINT")',
            persistent-hint,
            :rules='formRule.descRules', 
            no-resize
          )
          div(v-if="editData.column_id.slice(0,3) === 'opt' || editData.column_id.slice(0,3) === 'mlt'")
              .text-h6.mb-4 
              v-row(no-gutters)
                v-col.d-flex.align-center(cols="12")
                  h3.my-0 {{$t('CUSTOMFIELDS.OPTION')}}

              v-text-field#options.v-text-field__slot(
                type="text",
                :label=`$t('CUSTOMFIELDS.ERROR_6')`,
                v-model="tempOptions",
                @keyup.enter="addOptions"
              )
              v-card(v-show='Object.keys(editData.option_set).length>0' max-height="330" class='scroll' outlined elevation='0')
                v-card-text(height='100' max-height='260' class='custom-filter-style pa-1 ')
                  v-form(v-model='optionsValid' ref='optionsForm')
                    v-list(max-height='260' class='pa-0')
                      v-text-field.no-border.v-text-field__slot.pt-0(
                        type="text",
                        :rules='formRule.reqRules'
                        prepend-inner-icon="mdi-format-list-bulleted"
                        v-model="editData.option_set[index]",
                        v-for="(item,index) in editData.option_set", :key="index" :append-outer-icon="'icon-cancel'" @click:append-outer="deleteOtptions(index,'old')"
                        @blur='validateOptions'
                      )
      v-card-actions.px-2.py-4
        v-row
          v-col.pr-2(cols="6")
            v-btn(
              :ripple="false",
              @click="onCancel",
              width="100%",
              height="40",
              color="cancel",
              depressed,
              dark
            ) {{$t('GENERAL.CANCEL')}}
          v-col.pl-2(cols="6")
            v-btn.btn_green(
              :ripple="false",
              @click="onConfirm",
              width="100%",
              height="40",
              color="#2ace87",
              depressed,
              dark,
            ) 
              v-progress-circular(v-if='addBtn_loading' indeterminate size='20')
              .t-white(v-else) {{$t('GENERAL.SAVE')}}
      v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' v-if='messageDialog')
          message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message' )
      v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
          error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    
    </template>
    
    <script>
    import Vue from "vue";
    import messageDialog from '@/components/Dialog/redirectDialog';
    import errorDialog from "@/components/Dialog/errorDialog";
    import i18n from '/common/plugins/vue-i18n.js';
    
    
    export default Vue.extend({
      props: {
        fieldData:{
          type:Object,
          require:true
        }
      },
      components: {            
                messageDialog,
                errorDialog
            },
      data() {
        return {
          addBtn_loading:false,
          editData : {},
          messageDialog:false,
          message:"",
          errorDialog: false,
          errorMessage: "",
          valid: true,
          optionsValid: true,
          formRule: {
            reqRules: [
                (v) => !!v.trim() || i18n.t('RULE.RULE_R'),
                (v) => !!this.checkExist(v) || i18n.t('RULE.RULE_CF_1'),
            ],
            nameRules: [
                (v) => !!v.trim() || i18n.t('RULE.RULE_R'),
                (v) => /^.{0,20}$/.test(v) || i18n.t('RULE.RULE_20'),
            ],
            descRules: [
                (v) => !!v || i18n.t('RULE.RULE_R'),
                (v) => /^.{10,50}$/.test(v) || i18n.t('RULE.RULE_10_50'),
            ],
          },
          recordType: [
              {key: 'txt', name: 'Text'},
              {key: 'str', name: 'String' },
              {key: 'num', name: 'Number' },
              {key: 'dat', name: 'Date' },
              {key: 'dtt', name: 'Date Time' },
              {key: 'bol', name: 'Boolean' },
          ],
          processOptData:{},
          tempOptions: "",
          options: [],
          newOption:{},
          max_key:null,
          new_key:null,
          optType:null,
        };
      },
      created(){
        this.editData = JSON.parse(JSON.stringify(this.fieldData));
        if(this.editData.column_id.slice(0,3)=="opt" || this.editData.column_id.slice(0,3)=="mlt"){
          this.optType = this.editData.column_id.slice(0,3);
          this.max_key = this.editData.max_key ? this.editData.max_key : Math.max(...Object.keys(this.editData.option_set));
        }
      },
      methods: {
        validateForm() {
          if (this.$refs.form.validate()) 
            return true;
          else 
            return false;
        },
        async onConfirm() {
          if(this.optType=="opt" || this.optType=="mlt"){
            if(Object.entries(this.editData.option_set).length < 2)
              {
                this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR'));
                return
              }
            if(!this.validateOptions()) return
            this.editData.max_key = this.new_key ? this.new_key : this.editData.max_key;
          }
          if (this.validateForm()){
            this.editData.column_name = this.editData.column_name.trim();
            this.$emit("emitEditDialog", true ,this.editData);
          }
        },
        onCancel() {
          this.$emit("emitEditDialog", false);
        },
        showMessageDialog(msg) {                
            this.messageDialog = true;
            this.message = msg;
        },
        async onEmitMessageDialog(val) {
          if(val){
            // 
          }
        },
        showErrorDialog(message) {
          this.errorDialog = true;
          this.errorMessage = message;
        },
        onEmitErrorDialog() {
          this.errorDialog = false;
        },

        addOptions(e) {
          if(e.key === "Enter" && this.tempOptions){
            const optCheck = Object.values(this.editData.option_set).includes(this.tempOptions);
            if (!optCheck) {
              if(this.optType =="mlt" && this.tempOptions.includes(",")){
                return this.tempOptions ="";
              }
              if(this.new_key==null){
                this.editData.option_set[this.max_key+1] = this.tempOptions;
                this.new_key = this.max_key+1;
              }else{
                this.editData.option_set[this.new_key+1] = this.tempOptions;
                this.new_key = this.new_key+1;
              }
            }
            this.tempOptions = "";
          }else{        
            this.showErrorDialog(i18n.t('CUSTOMFIELDS.ERROR_2'));
          }
        },
        deleteOtptions(index) {
          this.processOptData = this.editData.option_set;
          for(let i in this.processOptData){
              if(parseInt(i) > index &&index > this.max_key){
                this.processOptData[index++] = this.processOptData[i++];
              }
          }
          Vue.delete(this.processOptData, index);
          if(this.max_key <= Math.max(...Object.keys(this.processOptData))){
            this.new_key = Math.max(...Object.keys(this.processOptData));
          }
        },
        validateOptions() {
          if (this.$refs.optionsForm.validate()) 
            return true;
          else 
            return false;
        },
        checkExist(item){
          let count =  Object.values(this.editData.option_set).filter(el => el==item.trim()).length;
          if(count>1) return false
          else return true;
        },
      },
    });
    </script>